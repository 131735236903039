import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import {
  ErrorMessage,
  Form,
  useFormikContext,
} from 'formik'
import {
  BirthdayInput,
  Button,
  Dropdown,
  Input,
  InputPhone,
  InputRadio,
  SmsTokenButton,
} from '../../../../../ui'
import {
  customStylesLanguageDropdown,
} from '../../../../../ui/dropdown/custom-styles'
import useStyles from './profile-form-style'

const ProfileFromView = (props) => {
  const {
    countryCallingCodeOptions,
    enableBirthdayEdit,
    genderSelection,
    t,
    requiredFields = {},
    formDisabled,
    showVerificationCodeInput,
    user,
    onRequestSmsTokenSuccess,
    onRequestSmsTokenError,
    onCancel,
    localeOptions,
  } = props
  const {
    dirty,
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
    submitForm,
    resetForm,
    values,
    initialValues,
  } = useFormikContext() || {}

  useEffect(() => (() => { resetForm() }), [])

  const smsTokenDisable = useMemo(() => (
    _.get(values, 'phone') === _.get(initialValues, 'phone')
    || _.has(errors, 'phone')
  ), [errors, values, initialValues])

  const handleBirthdayChange = (value) => {
    setFieldValue('dateOfBirth', value)
  }

  const handlePhoneChange = ({ countryCallingCode, localPhoneNumber }) => {
    setFieldValue('countryCallingCode', countryCallingCode)
    setFieldValue('localPhoneNumber', localPhoneNumber)
    const phone = !_.isEmpty(countryCallingCode) && !_.isEmpty(localPhoneNumber)
      ? `+${countryCallingCode}${localPhoneNumber}`
      : ''
    setFieldValue('phone', phone)
  }

  const handleLocalePreferenceChange = (event) => {
    setFieldValue('locale', _.get(event, 'value'))
  }

  const handleSubmitForm = () => {
    submitForm()
  }
  const styles = useStyles()
  return (
    <div className={styles.profileForm}>
      <h2 className={styles.title}>{t('screens.account.profile.form.title')}</h2>
      <Form noValidate autoComplete="off">
        <div className={styles.row}>
          <div className={styles.col}>
            <Input
              formik
              labelClassName={styles.label}
              label={t('screens.account.profile.form.lastName')}
              name="lastName"
              required={requiredFields.lastName}
            />
          </div>
          <div className={styles.col}>
            <Input
              formik
              labelClassName={styles.label}
              label={t('screens.account.profile.form.firstName')}
              name="firstName"
              required={requiredFields.firstName}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Input
              formik
              labelClassName={styles.label}
              label={t('screens.account.profile.form.screenName')}
              name="screenName"
              required={requiredFields.screenName}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Input
              formik
              labelClassName={styles.label}
              label={t('screens.account.profile.form.email')}
              placeholder=" "
              name="email"
              type="email"
              required={requiredFields.email}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputPhone
              formik
              labelClassName={styles.label}
              label={t('screens.account.profile.form.phone')}
              countryCallingCodeOptions={countryCallingCodeOptions}
              defaultValue={{
                countryCallingCode: initialValues.countryCallingCode,
                localPhoneNumber: _.replace(initialValues.phone, `+${initialValues.countryCallingCode}`, ''),
              }}
              name="phone"
              required={requiredFields.phone}
              onChange={handlePhoneChange}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.getSmsButtonContainer}>
              <SmsTokenButton
                dark
                disabled={smsTokenDisable}
                phone={`${_.get(values, 'countryCallingCode', '')}${_.get(values, 'localPhoneNumber', '')}`}
                onSuccess={onRequestSmsTokenSuccess}
                onError={onRequestSmsTokenError}
              />
            </div>
          </div>
        </div>
        {
          showVerificationCodeInput && (
            <div className={styles.row}>
              <div className={styles.col}>
                <Input
                  formik
                  labelClassName={styles.label}
                  label={t('screens.account.profile.form.token')}
                  name="token"
                  required={showVerificationCodeInput}
                />
              </div>
            </div>
          )
        }
        { (
          enableBirthdayEdit
          // FL: Let user edit date of birth if it is not provided
          //      even enableBirthdayEdit is disabled
          || _.isEmpty(_.get(user, 'dateOfBirth'))
        )
        && (
          <div className={styles.row}>
            <div className={styles.col}>
              <BirthdayInput
                className={styles.boxBirthday}
                label={t('screens.account.profile.form.birthday')}
                name="dateOfBirth"
                required={requiredFields.dateOfBirth}
                onChange={handleBirthdayChange}
                disabled={!enableBirthdayEdit}
                defaultValue={initialValues.dateOfBirth}
              />
            </div>
          </div>
        )}
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.genderContainer}>
              <p className={styles.asInputStyle}>{t('screens.account.profile.form.gender.title')}</p>
              <div className={styles.boxGender}>
                {
                  _.map(genderSelection, (gender) => {
                    if (_.isNull(gender)) return <></>
                    return (
                      <InputRadio
                        key={`gendre-${gender}`}
                        formik
                        id={`registration-gender-${gender}`}
                        label={t('screens.account.profile.form.gender.options', { context: gender })}
                        name="gender"
                        value={gender}
                      />
                    )
                  })
                }
                <ErrorMessage name="gender" />
              </div>
            </div>
          </div>
        </div>
        {
          _.size(localeOptions) > 1
          && (
            <div className={styles.row}>
              <div className={styles.col}>
                <Dropdown
                  label={t('screens.account.profile.form.locale')}
                  className={styles.dropdown}
                  placeholder={_.get(initialValues, 'locale', '')}
                  customStyles={customStylesLanguageDropdown}
                  options={localeOptions}
                  onChange={handleLocalePreferenceChange}
                  isSearchable={false}
                  values={_.find(localeOptions, { value: values.locale })}
                />
              </div>
            </div>
          )
        }
        <div className={styles.row}>
          <div className={styles.col}>
            <Button
              className={styles.button}
              dark
              text={t('screens.account.profile.buttons.save')}
              disabled={isSubmitting || !isValid || !dirty || formDisabled}
              onClick={handleSubmitForm}
            />
          </div>
          <div className={styles.col}>
            <Button
              className={styles.button}
              border
              text={t('screens.account.profile.buttons.cancel')}
              onClick={onCancel}
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

export default ProfileFromView
