import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  profileForm: {
    marginTop: `${theme.gap}rem`,
    // '& a': {
    //   display: 'block',
    //   fontSize: '1.4rem',
    //   color: theme.primary,
    //   marginTop: '1.6rem',
    //   marginBottom: '1.6rem',
    //   lineHeight: 1.14,

    // },
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: `-${theme.gap}rem`,
  },
  col: {
    width: '100%',
    marginRight: `${theme.gap}rem`,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      maxWidth: '32rem',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: '50%',
    },
  },
  title: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    marginBottom: '0.7rem',
  },
  buttons: {
    marginTop: `${theme.gap}rem`,
  },
  button: {
    marginBottom: '1rem',
  },
}))

export default useStyles
