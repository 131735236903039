/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Formik } from 'formik'
import URI from 'urijs'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useAddresses,
  useAuth,
  useSystemSettings,
  useUser,
  useOmnitechApp,
} from 'react-omnitech-api'
import {
  useAlert,
  useAnalytics,
  useCart,
  useLink,
  useI18n,
} from '../../../hook'
import {
  getUpdateProfileFormInitialValues,
  getSocialMediaAvailable,
  transformRegistrationFormValidationSchema,
  transformUpdatePasswordFormValidationSchema,
} from '../../../helpers'
import AccountProfileView from './account-profile-view'

function AccountProfileController(props) {
  // prepare hook
  const { location, pathContext } = props

  // Ref
  const formRef = useRef(null)

  const alert = useAlert()
  const { t } = useTranslation()
  const { navigate } = useLink()
  const { fetchCountries } = useAddresses()
  const {
    deleteSession,
    auth,
    deleteAuthenticationsByProvider,
    setAuth,
  } = useAuth()
  const {
    user,
    updateUser,
    deleteUser,
    resetUser,
    createUsersOmniAuthOtps,
  } = useUser()
  const { createCart, resetCart } = useCart()
  const { getSystemSetting } = useSystemSettings()
  const { availableLocales } = useI18n()
  const { api } = useOmnitechApp()
  const { trackEvent } = useAnalytics()

  // hook with dependencies
  const genderSelection = getSystemSetting('account.gender_selection', [])
  const enableBirthdayEdit = getSystemSetting('account.enable_birthday_edit', false)
  const formConfig = getSystemSetting('account.user_edit_profile_fields', {})

  // TODO get array all social sign in enable
  const socialAvailable = getSocialMediaAvailable(getSystemSetting)

  // internal states
  const [countriesEntities, setCountriesEntities] = useState([])
  const [defaultCountryCallingCode, setDefaultCountryCallingCode] = useState('')
  const [updateUserInProgress, setUpdateUserInProgress] = useState(false)
  const [formDisabled, setFormDisabled] = useState(true)
  const [pageReady, setPageReady] = useState(false)
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const [alerts, setAlerts] = useState('')

  // local variable
  const seoTitle = t('screens.accountProfile.seo.title')

  // TODO: change password
  // TODO: upload avatar
  // TODO: (Add-on) connect facebook

  /**
   * handleError
   * show error message via alert
   */
  function handleError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message)
  }

  // locale options
  const localeOptions = _.map(availableLocales, (availableLocale) => ({
    value: availableLocale,
    label: t('ui.locale', { context: availableLocale }),
  }))

  // transform the countries to usable format in select
  const countryCallingCodeOptions = useMemo(() => (
    countriesEntities.map((country) => ({
      label: country.callingCode,
      value: country.callingCode,
    }))
  ), [countriesEntities])

  const requiredFields = useMemo(() => (
    _.reduce(formConfig, (result, current, key) => {
      const newResult = result
      if (current.required) {
        newResult[key] = true
        if (current.requireConfirmation) {
          newResult[`${key}Confirmation`] = true
        }
      }
      return newResult
    }, {})
  ), [formConfig])

  /**
   * fetchCountriesApi
   * function to call api and fetch countries for country calling code options
   */
  const fetchCountriesApi = useCallback(async () => {
    try {
      const fetchCountriesApiQuery = getSystemSetting('api.v2.addresses.countries.registration.ecom.query', {})
      const { countries } = await fetchCountries({
        params: {
          pageSize: 999,
          ...fetchCountriesApiQuery,
        },
        arrayFormat: 'brackets',
      })
      const firstCountry = _.first(countries)
      setCountriesEntities(countries)
      setDefaultCountryCallingCode(firstCountry.callingCode)
      setPageReady(true)
    } catch (error) {
      handleError(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSystemSetting, fetchCountries])

  // useMemo for caching variables
  const formInitialValues = useMemo(() => (
    isChangingPassword
      ? {
        password: '',
        currentPassword: '',
        passwordConfirmation: '',
      }
      : getUpdateProfileFormInitialValues({ formConfig, userData: user, defaultCountryCallingCode })
  ), [defaultCountryCallingCode, formConfig, user, isChangingPassword])

  const formValidationSchema = useMemo(() => (
    isChangingPassword
      ? transformUpdatePasswordFormValidationSchema()
      : transformRegistrationFormValidationSchema(formConfig)
  ), [formConfig, isChangingPassword])

  /**
   * Start edit profile
   * @param {*} event
   */
  async function handleEditStart(event) {
    // prevent form submit by send sms button
    event.preventDefault()
    setIsChangingPassword(false)
    setIsEditing(true)
  }

  /**
   * Cancel edit profile
   * @param {*} event
   */
  async function handleEditCancel(event) {
    // prevent form submit by send sms button
    event.preventDefault()
    setIsEditing(false)
  }

  /**
   * Start edit Password
   */
  async function handleChangePasswordStart() {
    setIsEditing(false)
    setIsChangingPassword(true)
  }

  /**
   * Cancel edit Password
   */
  async function handleChangePasswordCancel() {
    setIsChangingPassword(false)
  }

  /**
   * Start delete user
   * @param {*} event
   */
  async function handleDeleteUser() {
    setPageReady(false)
    try {
      await deleteUser(user.id)
    } catch (error) {
      handleError(error)
    }

    try {
      deleteSession()
    } catch (error) {
      handleError(error)
    } finally {
      setAuth({
        ...auth,
        authToken: '',
        userId: '',
      })
      resetUser()
      resetCart()
      createCart({})
      setPageReady(true)
    }
  }

  // Handle SmsTokenButton events
  const onRequestSmsTokenSuccess = () => {
    setShowVerificationCodeInput(true)
  }
  const onRequestSmsTokenError = (error) => {
    handleError(error)
  }

  /**
   * handleEditProfile
   *
   * @param {*} values, object contain all value from input
   */
  async function handleEditProfile(values) {
    alert.remove()

    const userProfileFields = _.keys(formConfig)
    const optimizedValues = {
      ..._.pick(values, userProfileFields),
      ...(values.gender === '' ? { gender: null } : {}),
      ...(!_.isEmpty(values.phone) ? { smsToken: _.get(values, 'token', '') } : {}),
    }

    // prepare api call payload
    const data = {
      userId: user.id,
      user: optimizedValues,
    }

    await updateUser(data)
  }

  /**
   * handleUpdatePassword
   *
   * @param {*} values, object contain all value from input
   */
  async function handleUpdatePassword(values) {
    alert.remove()

    // prepare api call payload
    const data = {
      userId: user.id,
      user: values,
    }

    await updateUser(data)
  }

  /**
   * handleSubmit
   *
   * Formik onSubmit callback
   *
   * @param {*} values form values from Formik
   * @param {*} actions includes an object containing a subset of the injected props and methods
   */
  const handleSubmit = async (values, actions) => {
    setUpdateUserInProgress(true)
    const updatedValues = _.get(formRef, 'current.values', {})
    try {
      if (isChangingPassword) {
        await handleUpdatePassword(updatedValues)
        setIsChangingPassword(false)
      } else {
        await handleEditProfile(updatedValues)
        setIsEditing(false)
      }
    } catch (error) {
      handleError(error)
    } finally {
      setUpdateUserInProgress(false)
      actions.setSubmitting(false)
    }
  }

  /**
   * onSocialSignIn
   *
   * redirect facebook
   * TODO implement logical for other sacial platforms
   */

  async function handleSocialSignIn(provider) {
    const isConnect = _.includes(user.connectedSocialLoginProviders, provider)
    alert.remove()
    const url = new URI(location.href)
    // use a cross-platform application like NGROK to test in localhost
    const urlRedirect = `${url.protocol()}://${url.host()}/${pathContext.locale}/oauth/connect`

    // prepare api call payload
    const data = {
      omniAuthOtp: {
        provider,
        mode: 'redirect',
        redirect: false,
        redirectUrl: urlRedirect,
      },
    }

    // calling api for create session and control the flow of page redirect
    try {
      let response = null
      if (isConnect) {
        response = await deleteAuthenticationsByProvider(provider)
        window.location.reload()
      } else {
        response = await createUsersOmniAuthOtps(data)
        const urlAPI = _.get(api, 'host')
        const urlAuth = _.get(response.omniAuthOtp, 'requestPhasePath')
        const newUrl = `${urlAPI}${urlAuth}`
        // handle redirect to different page after sign in successfully
        navigate(
          newUrl,
          { replace: false },
        )
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)

      setFormDisabled(false)

      throw error
    }
  }

  /**
   * when the page is loaded
   * without user logged in, redirect to login page
   */
  useEffect(() => {
    if (!auth.userId) {
      navigate(
        '/login/',
        {
          state: {
            redirectUrl: '/account/profile/',
          },
          replace: true,
        },
      )
    } else {
      try {
        // track view profile
        trackEvent('customerViewProfile', {}, {
          user: _.pick(user, ['email', 'phone', 'firstName', 'lastName']),
        })
      } catch (ex) {
        // do nothing
      }
    }
  }, [])

  useEffect(() => {
    alert.show(alerts)
  }, [alerts])

  useEffect(() => {
    if (!_.isEmpty(_.get(location, 'state.errorMessage'))) {
      setAlerts(_.get(location, 'state.errorMessage'))
    }
  }, [location])

  /**
   * get countries for country call code option
   */
  useEffect(() => {
    fetchCountriesApi()

    return () => {
      cancelRequest.cancelAll(['fetchCountries'])
    }
  }, [fetchCountriesApi])

  useEffect(() => {
    setFormDisabled(
      _.isEmpty(countriesEntities)
      || updateUserInProgress,
    )
  }, [countriesEntities, updateUserInProgress])

  const formPorps = {
    countryCallingCodeOptions,
    localeOptions,
    enableBirthdayEdit,
    formConfig,
    formDisabled,
    genderSelection,
    requiredFields,
    showVerificationCodeInput,
  }

  const viewProps = {
    pageReady,
    defaultCountryCallingCode,
    user,
    isEditing,
    isChangingPassword,
    seoTitle,
    socialAvailable,
    onSocialSignIn: handleSocialSignIn,
    onDeleteUser: handleDeleteUser,
    onEditStart: handleEditStart,
    onEditCancel: handleEditCancel,
    onChangePasswordStart: handleChangePasswordStart,
    onChangePasswordCancel: handleChangePasswordCancel,
    onRequestSmsTokenSuccess,
    onRequestSmsTokenError,
    ...formPorps,
  }

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={formInitialValues}
      validateOnChange
      validationSchema={formValidationSchema}
      onSubmit={handleSubmit}
    >
      <AccountProfileView
        {...viewProps}
      />
    </Formik>
  )
}

export default AccountProfileController
