import _ from 'lodash'
import React from 'react'
import iconBuilder from '../../../../../helpers/iconBuilder'
import useStyles from '../../account-profile-style'
import {
  Button,
} from '../../../../../ui'

const ProfileSocialLogin = ({
  connectedSocialLoginProviders,
  social,
  onSocialSignIn,
  t,
}) => {
  const styles = useStyles()
  const isConnected = _.includes(connectedSocialLoginProviders, social.name)
  const connected = isConnected
    ? t('screens.account.profile.info.connected')
    : t('screens.account.profile.info.notConnected')
  const isConnectedText = isConnected
    ? t('screens.account.profile.buttons.disconnect')
    : t('screens.account.profile.buttons.connect')

  if (!social.available) return null

  return (
    <div className={styles.socialMediaRow} key={social.name}>
      <div className={styles.socialMediaBoxIcon}>
        <div
          style={{ backgroundColor: social.backgroundColor }}
          className={styles.socialMediaIcon}
        >
          <img src={iconBuilder('facebookround')} alt={social.name} />
        </div>
        <div className={styles.socialMediaContent}>
          <span>{social.name}</span>
          <span>{connected}</span>
        </div>
      </div>
      <div>
        <Button
          border
          padding="1.25rem"
          width="14rem"
          text={isConnectedText}
          onClick={() => onSocialSignIn(social.name)}
        />
      </div>
    </div>
  )
}

export default ProfileSocialLogin
