import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    maxWidth: '67rem',
  },
  content: {
    // padding: '0 0.6rem',
    marginBottom: '3rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: 0,
    },
  },
  row: {
    margin: '1.6rem 0',
  },
  label: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: 1.143,
    color: theme.colors.text,
    textTransform: 'uppercase',
    marginBottom: '0.4rem',
  },
  value: {
    fontSize: '1.4rem',
    lineHeight: 1.142,
    margin: 0,
    color: theme.colors.bgDark,
  },
  actions: {
    marginBottom: '-0.2rem',
    maxWidth: '37rem',

    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      maxWidth: '37rem',
    },
  },
  button: {
    minHeight: '5rem',
    marginBottom: '1.2rem',
  },
  buttonDelete: {
    fontSize: '1.4rem',
    fontWeight: 'normal',

  },
}))

export default useStyles
