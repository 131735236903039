import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    padding: [[30, 20]],
    textAlign: 'left',
  },
  boxInfo: {
    flex: '1 1 100%',
  },
  header: {
    marginBottom: '2rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginBottom: '3rem',
    },
  },
  title: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25,
    color: theme.colors.text,
    textTransform: 'uppercase',
    margin: 0,
  },
  intro: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    color: theme.colors.bgDark,
    margin: '0.25em 0',
  },
  sideButtons: {
    flex: '1 1 100%',
    display: 'flex',
    borderTop: `1px solid ${theme.colors.bgDark}`,
    paddingTop: '3.5rem',
    flexDirection: 'column',
  },
  sideButtonsInner: {
    maxWidth: '37rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      width: '37rem',
      '& > div': {
        marginBottom: '6rem',
      },
    },
  },
  socialMediaRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '6rem',
    marginTop: '2.6rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      alignItems: 'center',
      width: '37rem',
      flexDirection: 'row',
      marginBottom: 0,
      marginTop: 0,
    },
  },
  socialMediaContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '2rem',
    '& span': {
      margin: 0,
      textTransform: 'capitalize',
      fontSize: '1.6rem',
      '&:first-child': {
        fontWeight: 600,
      },
    },
  },
  socialMediaBoxIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginBottom: 0,
    },
  },
  socialMediaIcon: {
    backgroundColor: '#000',
    height: '4rem',
    width: '4rem',
    padding: '1rem',
    borderRadius: '2rem',
    '& img ': {
      width: '3rem',
      maxWidth: '100%',
    },
  },
  sideButtonsLabel: {
    fontSize: '1.6rem',
    color: theme.colors.text,
    textTransform: 'uppercase',
    marginBottom: '0.8rem',
    lineHeight: 1.25,
  },
  dialogContainer: {
    maxWidth: '33.8rem',
    margin: '0 auto',
    padding: '0.8rem 0',
  },
  dialogBody: {
    marginBottom: '3rem',
  },
  dialogTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.333,
    marginBottom: '0.4rem',
  },
  dialogDescription: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    marginBottom: '0.4rem',
  },
  dialogButtons: {
    marginBottom: '-1.6rem',
  },
  button: {
    marginBottom: '1.6rem',
  },

  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    container: {
      padding: [[60, 0]],
      width: '100%',
      display: 'flex',

    },
    boxInfo: {
      flex: '1 1 50%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sideButtons: {
      flex: '1 1 50%',
      borderTop: 'none',
      marginTop: '6.8rem',
      paddingTop: 0,
      alignItems: 'center',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    container: {
      padding: [[60, 0]],
      width: 1170,
      display: 'flex',
    },
  },
}))

export default useStyles
