// import _ from 'lodash'
import React, { useEffect } from 'react'
import {
  Form,
  useFormikContext,
} from 'formik'
import {
  Button,
  Input,
} from '../../../../../ui'
import useStyles from './update-password-form-style'

const ProfileFromView = (props) => {
  const {
    t,
    formDisabled,
    onCancel,
  } = props
  const {
    isSubmitting,
    isValid,
    submitForm,
    resetForm,
  } = useFormikContext() || {}

  useEffect(() => (() => { resetForm() }), [])

  const handleSubmitForm = () => {
    submitForm()
  }
  const styles = useStyles()
  return (
    <div className={styles.profileForm}>
      <h2 className={styles.title}>{t('screens.account.profile.updatePassword.title')}</h2>
      <Form noValidate>
        <div className={styles.row}>
          <div className={styles.col}>
            <Input
              formik
              label={t('screens.account.profile.form.currentPassword')}
              name="currentPassword"
              type="password"
              autoComplete="current-password"
              required
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Input
              formik
              label={t('screens.account.profile.form.password')}
              name="password"
              type="password"
              autoComplete="new-password"
              required
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Input
              formik
              label={t('screens.account.profile.form.passwordConfirmation')}
              name="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              required
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Button
                className={styles.button}
                dark
                uppercase
                text={t('screens.account.profile.buttons.updatePassword')}
                disabled={isSubmitting || !isValid || formDisabled}
                onClick={handleSubmitForm}
              />
            </div>
            <div className={styles.col}>
              <Button
                className={styles.button}
                uppercase
                border
                text={t('screens.account.profile.buttons.cancel')}
                onClick={onCancel}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default ProfileFromView
